
import {computed, defineComponent, onUpdated} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import EntityView from "@/components/base/common/EntityView.vue";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import CompanyForm from "@/views/company/CompanyForm.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import Swal from "sweetalert2";
import CompanyService from "@/core/services/CompanyService";
import {ModelAddress} from '@/core/entity/IAddress';

export default defineComponent({
  name: "Company",
  components: {TabNavigate, CompanyForm, BaseModal, QuickAction, DateTimeFormat, EntityView},
  setup() {

    const company = computed(() => store.state.CompanyModule.company)
    onUpdated(() => {
      const link =`/list-company/` + (company.value.type.code === 'CLIENT'  ?  `clients` : `ppos`)
      console.log(link);
      setCurrentPageBreadcrumbsWithParams('Company Details', [
        {link: true, router: link, text: 'Companies'},
        {link: false, router: '', text: 'View Company'}
      ])
    })


    return {
      company,
      ...LoadEntity(Actions.LOAD_COMPANY),
      ModelAddress,
    }
  },
  methods: {
    onCompanySaved() {
      const baseModal = this.$refs.refCompanyModal as typeof BaseModal
      baseModal.hideBaseModal()
      this.reloadData();
    },
    onDelete(company) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await CompanyService.delete(company.id).then(() => {
            this.$router.push({
              name: 'companies'
            })
          })
        }
      })
    }
  }
})
